import React, { useState, useEffect } from "react";
import "./Toggler.css";

const Toggler = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(props.remote);
  }, [props.remote]);

  const onToggleSwitchChange = () => {
    props.fire();
    if (!props.disabled) {
      setIsChecked(!isChecked);
      props.setRemote(!isChecked);
    }
  };

  return (
    <div
      className={`ToggleSwitch ToggleSwitch__rounded ${
        props.disabled && "disabled"
      } `}>
      <div className='ToggleSwitch__wrapper'>
        <div
          className={`Slider ${isChecked && "isChecked"} ${
            props.disabled && "disabled"
          }`}
          onClick={onToggleSwitchChange}></div>
      </div>
    </div>
  );
};

export default Toggler;
