import React, { useEffect } from "react";
import TotalEnergyCardContent from "./TotalEnergyCardContent";
import { Pie } from "chartist";
import "chartist/dist/chartist.css";
import "./TotalEnergy.css";
import "./CumulativeData.css";

const TotalEnergyCard = (props) => {
  useEffect(() => {
    const onScreenToday = document.getElementById("EnergyToday") || false;
    const onScreenYesterday =
      document.getElementById("EnergyYesterday") || false;
    const onScreenLastWeek = document.getElementById("EnergyChart0") || false;
    const onScreenLastMonth = document.getElementById("EnergyChart1") || false;
    const onScreenLastYear = document.getElementById("EnergyChart2") || false;
    const onScreenLastTotal = document.getElementById("EnergyChart3") || false;

    const containersOnScreen =
      onScreenToday &&
      onScreenYesterday &&
      onScreenLastWeek &&
      onScreenLastMonth &&
      onScreenLastYear &&
      onScreenLastTotal;

    if (props.energyCumData.length !== 0 && containersOnScreen) {
      new Pie(
        "#EnergyToday",
        {
          series: props.energyCumData.today.every((item) => item === 0)
            ? [0, 0, 0, 100]
            : props.energyCumData.today,
        },
        {
          donut: true,
          donutWidth: 10,
          donutSolid: true,
          startAngle: 270,
          showLabel: false,
          height: 80,
          width: 80,
        }
      ).on("created", function (context) {
        var radius = context.svg.width() / 2;
        // console.log(radius);
      });

      new Pie(
        "#EnergyYesterday",
        {
          series: props.energyCumData.yesterday.every((item) => item === 0)
            ? [0, 0, 0, 100]
            : props.energyCumData.yesterday,
        },
        {
          donut: true,
          donutWidth: 10,
          donutSolid: true,
          startAngle: 270,
          showLabel: false,
          height: 80,
          width: 80,
        }
      );

      Object.keys(props.donutList).map((keyName, i) => {
        new Pie(
          "#EnergyChart" + i,
          {
            series: props.donutList[keyName].every((item) => item === 0)
              ? [0, 0, 0, 100]
              : props.donutList[keyName],
          },
          {
            donut: true,
            donutWidth: 10,
            donutSolid: true,
            startAngle: 270,
            showLabel: false,
            height: 80,
            width: 80,
          }
        );
      });
    }
  }, [props.energyCumData, props.donutList]);

  return (
    <div className='Energy-wrapper'>
      {props.energyCumData.today !== undefined ? (
        props.mode === "today" ? (
          <TotalEnergyCardContent
            id='Today'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.energyCumData.today[0]}
            pv={props.energyCumData.today[1]}
            pvOutput={props.energyCumData.today[2]}
          />
        ) : props.mode === "yesterday" ? (
          <TotalEnergyCardContent
            id='Yesterday'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.energyCumData.yesterday[0]}
            pv={props.energyCumData.yesterday[1]}
            pvOutput={props.energyCumData.yesterday[2]}
          />
        ) : null
      ) : null}
      {props.donutList.week !== undefined ? (
        props.mode === "week" ? (
          <TotalEnergyCardContent
            id='Chart0'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.donutList.week[0]}
            pv={props.donutList.week[1]}
            pvOutput={props.donutList.week[2]}
          />
        ) : props.mode === "month" ? (
          <TotalEnergyCardContent
            id='Chart1'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.donutList.month[0]}
            pv={props.donutList.month[1]}
            pvOutput={props.donutList.month[2]}
          />
        ) : props.mode === "year" ? (
          <TotalEnergyCardContent
            id='Chart2'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.donutList.year[0]}
            pv={props.donutList.year[1]}
            pvOutput={props.donutList.year[2]}
          />
        ) : props.mode === "total" ? (
          <TotalEnergyCardContent
            id='Chart3'
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
            grid={props.donutList.total[0]}
            pv={props.donutList.total[1]}
            pvOutput={props.donutList.total[2]}
          />
        ) : null
      ) : null}
    </div>
  );
};

export default TotalEnergyCard;
