let baseURL = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  // talky-things
  baseURL = "http://talky-things.com:8111";
  // talky-things IP
  // baseURL = "http://188.166.159.127:8111";
  // Mockoon
  // baseURL = "http://localhost:8002";
} else {
  // production code
  baseURL = new URL("/", window.location);
  // Testing only on talky-things
  // baseURL = new URL("http://talky-things.com:8111");
}
export default baseURL;
