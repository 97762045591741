import React from "react";

const InfoPopover = (props) => {
  const updateStateArr = (state, setter, value, index) => () => {
    let copy = [...state];
    copy[index] = value;
    setter(copy);
  };

  return (
    <span
      className='info-balloon'
      onMouseEnter={() =>
        !props.isClicked[props.index] &&
        updateStateArr(props.isPopOver, props.setIsPopOver, true, props.index)()
      }
      onClick={() =>
        !props.isClicked[props.index]
          ? (updateStateArr(
              props.isClicked,
              props.setIsClicked,
              true,
              props.index
            )(),
            updateStateArr(
              props.isPopOver,
              props.setIsPopOver,
              true,
              props.index
            )())
          : (updateStateArr(
              props.isClicked,
              props.setIsClicked,
              false,
              props.index
            )(),
            updateStateArr(
              props.isPopOver,
              props.setIsPopOver,
              false,
              props.index
            )())
      }
      onMouseLeave={() =>
        !props.isClicked[props.index] &&
        updateStateArr(
          props.isPopOver,
          props.setIsPopOver,
          false,
          props.index
        )()
      }>
      <span className='popover-icon'>i</span>
      <div
        id='popover-container'
        className={`${props.isPopOver[props.index] && "show"} ${
          props.message.length > 90 && "multi-line"
        }`}>
        <div className='popover-arrow'></div>
        <div
          className={`popover-inner ${
            props.message.length > 90 && "multi-line"
          }`}>
          {props.message}
        </div>
      </div>
    </span>
  );
};

export default InfoPopover;
