import React, { useEffect, useState, Fragment } from "react";
import TotalEnergyCard from "./TotalEnergyCard/TotalEnergyCard";

const CumulativeDataWrapper = (props) => {
  return (
    <div className='Cumul-Data-Wrapper'>
      {props.energyCumData && props.donutList ? (
        <Fragment>
          <TotalEnergyCard
            energyCumData={props.energyCumData}
            donutList={props.donutList}
            mode={props.mode}
            dashboardType={props.dashboardType}
            dashboardSubType={props.dashboardSubType}
          />
        </Fragment>
      ) : (
        <div id='dash-cumulative-data-loading-spinner' className='lds-ring'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default CumulativeDataWrapper;
